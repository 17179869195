<template lang="">
    <v-card>
        
        <s-crud
            :config="config"
            title="Creación de Periodos"
            :filter="filter"
            search-input
            add
            edit
            remove
            @save="save($event)"
            ref="scrudCreationPeriods"
        >
            <!-- <template v-slot:filter>
                <v-container>
                    <v-row style="margin-bottom: 5px;">
                        <v-col>
                            <s-text
                                label="Sugerencia"
                                v-model="filter.PbrSuggestion"
                            ></s-text>
                        </v-col>
                        <v-col>
                            <s-date
                                v-model="filter.PbrDateDesde"
                                label="Fecha Desde"
                            ></s-date>
                        </v-col>
                        <v-col>
                            <s-date
                                v-model="filter.PbrDateHasta"
                                label="Fecha Hasta"
                            ></s-date>
                        </v-col>
                    </v-row>
                </v-container>
            </template> -->
            <template v-slot="props">
                <v-container>
                    <v-row > 
                        <v-col class="s-col-form" cols="6" md="2">
                            <s-text type="number" v-model="props.item.PdsAnio" label="Año"></s-text>
                        </v-col>                                       
                        <v-col class="s-col-form" cols="6" md="2">
                            <s-select-definition                                
                                :def="1537"
                                label="Sub Periodo"
                                v-model="props.item.PdsType"
                                />
                        </v-col>
                        <v-col class="s-col-form" cols="12" md="2">
                            <s-text label="Nombre de Sub Periodo" v-model="props.item.PdsName"></s-text>
                        </v-col>
                        <v-col class="s-col-form" cols="6" md="2">
                            <s-date
                                v-model="props.item.PdsDateBegin"
                                label="Fecha Inicio de Contabilización"
                                />
                        </v-col>
                        <v-col class="s-col-form" cols="6" md="2">
                            <s-date
                                v-model="props.item.PdsDateEnd"
                                label="Fecha Fin de Contabilización"
                                />
                        </v-col>
                        <v-col class="s-col-form" cols="12" md="2">
                            <s-select-definition                                
                                :def="1538"
                                label="Estado"
                                v-model="props.item.PdsStatus"
                                />
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </s-crud>
    </v-card>

</template>

<script>

import SText from '../../components/Utils/SText.vue';
import CreationPeriods from '../../services/HumanResource/CreationPeriods';
import sToolbarPerson from "@/components/Utils/Person/sToolbarPerson";

export default {
    components: {
        SText,
        sToolbarPerson
    },
    data() {
        return {
            filter: {
                PdsDateBegin: '',
                PdsDateEnd: '',
            },
            config: {
                service: CreationPeriods,
                model: {
                    PdsID: "ID",
                    PdsDateBegin: "date",
                    PdsDateEnd: "date",
                },
                headers: [
                    { text: "ID", value: "PdsID" },
                    { text: "Nombre", value: "PdsName" },
                    { text: "Año", value: "PdsAnio" },
                    { text: "Tipo", value: "PdsTypeName" },
                    { text: "Estado", value: "PdsStatusName" },
                    { text: "Fecha Inicio", value: "PdsDateBegin" },
                    { text: "Fecha Fin", value: "PdsDateEnd" },
                ]
            }
        }
    },
    methods: {
        save(item){
            // console.log(item);
            item.PdsName = this.$fun.trimFull(item.PdsName, true, "-");
            item.UsrCreateID = this.$fun.getUserID();
            item.save();
        }
    }
}

</script>